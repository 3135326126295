<template>
  <div class="inline-flex items-center justify-start text-center gap-2">
    <div v-for="(item, index) in items" :key="`${index}-${item.time}`" class="flex flex-row gap-1">
      <span class="flex -ml-1 items-center font-bold" v-if="index > 0">-</span>
      <div class="label-base rounded-sm shadow-none w-14 text-white m-0 py-0.5" :class="[item.background || '', labelClass]" @click="item.onClick">
        <span>{{ item?.time ? timeString(item.time) : '?' }}</span>
      </div>
    </div>
    <div v-if="showAll" class="font-bold pt-0.5 inline-flex items-center justify-start text-center gap-3 ml-1">
      <span class="text-blue-700">
        {{ store.getters.wagen(wagen_id || -1)?.nummerplaat || '?' }}
      </span>
      <span class="text-purple-700">
        {{ chiron_status ? chiron_status : '?' }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { timeString } from '@/functions/formatDate'
import { useStore } from 'vuex'

const props = defineProps({
  start: [String, Number, Date, Object],
  aangekomen: [String, Number, Date, Object],
  klant_ingestapt: [String, Number, Date, Object],
  klant_afgezet: [String, Number, Date, Object],
  einde: [String, Number, Date, Object],
  wagen_id: [String, Number],
  chiron_status: String,
  labelClass: String,
  compact: Boolean,
  alerts: Boolean,
  showAll: Boolean,
})

const store = useStore()

const items = computed(() => {
  const result = [{
    time: props.start,
    background: 'bg-green-500',
    onClick: () => props.alerts && alert(`Shift gestart: ${props.start ? timeString(props.start) : '?'}`),
  }]

  if (props.showAll || (props.aangekomen && props.klant_ingestapt && props.klant_afgezet)) {
    result.push({
      time: props.aangekomen,
      background: 'bg-teal-500',
      onClick: () => props.alerts && alert(`Aangekomen op ophaallocatie/luchthaven: ${props.aangekomen ? timeString(props.aangekomen) : '?'}`),
    })
    result.push({
      time: props.klant_ingestapt,
      background: 'bg-purple-500',
      onClick: () => props.alerts && alert(`Klant ingestapt: ${props.klant_ingestapt ? timeString(props.klant_ingestapt) : '?'}`),
    })
    result.push({
      time: props.klant_afgezet,
      background: 'bg-orange-500',
      onClick: () => props.alerts && alert(`Klant afgezet: ${props.klant_afgezet ? timeString(props.klant_afgezet) : '?'}`),
    })
  }

  result.push({
    time: props.einde,
    background: 'bg-red-600',
    onClick: () => props.alerts && alert(`Klant afgezet: ${props.einde ? timeString(props.einde) : '?'}`),
  })

  return result
})
</script>
